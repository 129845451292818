












/* eslint-disable */
// Simple filtered dropdownList. List get from props
import Vue from 'vue'
import { Prop } from 'vue/types/options'

export default Vue.extend({
    props: { 
        labels: Array as any, 
        listItem: Object as Prop<any> 
    },
    data() {
        return {
            isActionActive: false
        }
    },

    methods: {
        bodyElClicked() {
            let bodyEl = document.body
            bodyEl.removeEventListener('click', this.bodyElClicked)
            this.isActionActive = false
        },
        openActionToggle(e: any) {
            if (this.isActionActive) {
                this.isActionActive = false
                let bodyEl = document.body
                bodyEl.removeEventListener('click', this.bodyElClicked)
                return false

            } else {  // TODO: addEventListener once          
                // e.stopPropagation() // close
                this.isActionActive = true

                // Add eventListerner async (with delay) after el click event propagate to boby
                // Reason: prevent incorrect behaviour: add eventListener, click event propagate to body and fire event this.bodyElClicked
                setTimeout(()=>{
                    let bodyEl = document.body
                    bodyEl.addEventListener('click', this.bodyElClicked)
                }, 100)
            }
        },
        actionClicked(actionIdx: number, e: any) {
            this.$emit('action', {item: this.listItem, idx: actionIdx})

            e.stopPropagation()
            let bodyEl = document.body
            bodyEl.removeEventListener('click', this.bodyElClicked)
            this.isActionActive = false
        }
    },

    destroyed() {
        let bodyEl = document.body
        bodyEl.removeEventListener('click', this.bodyElClicked)
    }

})

