



























/* eslint-disable */
import Vue from 'vue'
import { Prop } from 'vue/types/options'

import { setAppState } from "../services/data-mutator"

// TODO: update tabs {name: 'Super tab', key: 'tab1'}

export default Vue.extend({
    props: {
        appstate: Object as Prop<any>,
        tabs: Array as Prop<any>
    },
    data() {
        return {
            setAppState,

            activeTab: ''
        }
    },

    created() {
        this.$watch('appstate.activeTabIdx',
            () => {
                this.scrollTab()
            }
        )   
    },

    beforeMount() {
        // Set first tabl active if activeTabIdx is empty
        if (!this.appstate.activeTabIdx ) setAppState('activeTabIdx', 0)
    },

    mounted() {
        this.scrollTab()
    },

    methods: {
        scrollTab() {
            // Warn: ignore change other page tabs activeIdx if keep-alive (app store page state)

            // check active tab is off screen and scroll active tab to center
            let screenWidth = screen.width
            let tabsScroller: any = this.$refs.tabsScroller
            let tabsEls: any = tabsScroller.querySelectorAll('.mdc-tab')
            let activeTab: any = tabsEls[ this.appstate.activeTabIdx || 0 ] 
            let activeTabRight = activeTab.getBoundingClientRect().right
            let activeTabLeft = activeTab.getBoundingClientRect().left

            if (screenWidth < activeTabRight) { // if activeTab offScreen right
                if (this.appstate.activeTabIdx+1 === tabsEls.length) { // if last tab - set activeTab to screen right border
                    let activeTabOffsetRight = screenWidth - activeTabRight
                    tabsScroller.scrollLeft -= activeTabOffsetRight                    
                } else { // set to center NOT last tab
                    let screenCenter = screenWidth/2
                    let activeTabHalfWith = activeTab.getBoundingClientRect().width/2
                    tabsScroller.scrollLeft += (activeTabLeft + activeTabHalfWith - screenCenter)
                }
            } else if (activeTabLeft < 0) { // if active tab offscreen left
                if (this.appstate.activeTabIdx === 0) { // if first tab - set activeTab to screen left border
                    tabsScroller.scrollLeft = 0
                } else { // set to center NOT first tab
                    let screenCenter = screenWidth/2
                    let activeTabHalfWith = activeTab.getBoundingClientRect().width/2
                    tabsScroller.scrollLeft += (activeTabLeft + activeTabHalfWith - screenCenter)
                }
            }
        }
    }
})
