







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable */
import Vue from "vue";
import { Prop } from "vue/types/options";

import { countryList } from "@/store/appdata";
import eventsRegister from "@/services/events-register";
import { createRequest } from "@/services/api-connector";
import { getObjInAr, logEventFA } from "@/utils/utils";
import { leftArrowSVG } from "@/services/svg";
import PageTabs from "@/components/Tabs.vue";
import TransitionExpand from "@/components/TransitionExpand.vue";
import ListItemActionDots from "@/components/controls/ListItemActionDots.vue";
import { mapGetters } from "vuex";

// // TabsContent swipe
// let scrollEl: any;
// let tabsLen = 0;
// let activeTabIdxLocal = 0;

function getWarehouseName(id: number): string {
  let warehouseShortNames = { 287: "Shenzhen", 286: "HongKong", 284: "NJ" };
  return warehouseShortNames[id];
}

export default Vue.extend({
  components: {
    PageTabs,
    ListItemActionDots,
    TransitionExpand,
  },
  props: {
    routeparam: Object as Prop<any>,
  },
  data() {
    return {
      eventsRegister,
      leftArrowSVG,

      savedAddressList: [] as any[], // [ {ID:284, pr229:11028, pr234:"16", pr235:null, pr_256:"03038", pr_389:null, pr363:1924067, pr364_En:"Bucha", pr364_Name:"Буча",pr233_Name:"Нове", pr202_Name:"Київська", pr_471_Name:"Костянтин", pr_225:"Круцько", pr_471:2539746, pr_222:"НП 3 Буча", pr375:null, pr_298:true, pr363_Name:"Відділення №3 (до 30 кг): вул. Нове Шосе, 8а"},...]
      isSavedAddressesLoaded: false,

      searchText: "",

      warehousesList: [] as any[],
      isWarehousesLoaded: false,
      isWarehouseCardOpen: false,
      activeWarehouseData: {
        city: "",
        district: "",
        name: "",
        phone: "",
        postCode: "",
        state: "",
        streetAddress: "",
        title: "",
        pr201: 0,
      },

      activeDetailId: 0,
    };
  },

  computed: {
    ...mapGetters("user", ["userId"]),
    ...mapGetters("parcels", ["wholeParcelList"]),
    warehousesListFiltered(): any[] {
      return this.warehousesList;
    },

    savedAddressListFiltered(): any[] {
      return this.savedAddressList;
    },
  },

  created() {
    this.fetchWarehouses();
    this.fetchSavedAddresses();

    // Watcher for handle routeparam changes and open active tab
    // this.$watch(
    //   "appstate.activeTabIdx",
    //   (newVal: any, oldVal: any) => {
    //     if (newVal === activeTabIdxLocal) return false;
    //     activeTabIdxLocal = newVal;
    //     scrollEl.style.transform =
    //       "translateX(-" + activeTabIdxLocal * 100 + "%)";
    //     updateTouchTabsParams(activeTabIdxLocal);
    //   },
    //   { deep: true }
    // );

    // Watcher for handle addressesUpdate
    this.$root.$on(eventsRegister.updateAddresses, this.fetchSavedAddresses);
  },

  methods: {
    fetchSavedAddresses() {
      this.savedAddressList = []; // Reset savedAddresses list
      this.isSavedAddressesLoaded = false;

      createRequest(
        "address",
        null,
        (responseData: any) => {
          // Success callback function
          // pr229: 11212 - home address, 11028 - postDepartment
          let responseSavedAddress = responseData["sm_address_list"];
          if (!responseSavedAddress) {
            this.isSavedAddressesLoaded = true;
            return false;
          }

          // [{ID:272, pr229:11212, pr234:"12", pr235:"1700",pr_256: "03038",pr_389: null,pr363: null,pr364_En: "Kyiv",pr364_Name: "Київ",pr233_Name: "Амосова Миколи",pr202_Name: "Київська",pr_471_Name: "Костянтин",pr_225: "Круцько",pr_471: 1633932,pr_222: "Офис",pr375: null,pr_298: false,pr363_Name: null,pr202: 432,pr233: 536145,pr364: 28879},...]
          responseSavedAddress.forEach((addresObjItem: any) => {
            // Set default address on top
            if (addresObjItem.pr_298)
              this.savedAddressList.unshift(addresObjItem);
            else this.savedAddressList.push(addresObjItem);
          });

          this.isSavedAddressesLoaded = true;
        },
        () => {
          // Error callback function
          this.isSavedAddressesLoaded = true;
        }
      );
    },

    fetchWarehouses() {
      createRequest(
        "werehouses",
        null,
        (responseData: any) => {
          // success callback. Get countryes
          // warehouse data: pr493: "{"title": " Shenzhen", "name": " Ken (叶东仪)", "streetAddress": "西乡街"...}"
          // set warehouse list with details (iso code)
          const warehousesList = [] as any;

          responseData["sm_wh"].forEach((warehouseItem: any) => {
            // set country iso code
            if (warehouseItem.pr201 === 286) return false;

            warehouseItem["pr201_iso"] = "";
            if (warehouseItem.pr201) {
              // [{CalcName:"Ukraine", Object_id: 285, pr_587: "UA"}]
              let countryObj: any = getObjInAr(
                countryList,
                "Object_id",
                warehouseItem.pr201
              );
              warehouseItem["pr201_iso"] = countryObj["pr_587"];
            }
            // set string address from pr493 json
            // Check JSON or text. New app version JSON, old - text
            if (warehouseItem["pr493"].indexOf("{") === 0) {
              let addressJSON = JSON.parse(warehouseItem["pr493"]);
              let addressStr = "";
              if (addressJSON["postCode"])
                addressStr = addressJSON["postCode"] + " ";
              addressStr +=
                addressJSON["state"] +
                ", " +
                addressJSON["city"] +
                ", " +
                addressJSON["streetAddress"];
              if (warehouseItem.pr201 === 286 || warehouseItem.pr201 === 287) {
                // china warehouse
                // Warn: do not format multiline string
                warehouseItem["pr493_str"] = `${addressStr}#EG${this.userId}
${this.$t("contactWrhCN")}: ${addressJSON["name"]}, 
${this.$t("phoneWrhListCN")}: ${addressJSON["phone"]}`;
              } else {
                // usa warehouse
                // Warn: do not format multiline string
                warehouseItem["pr493_str"] = `${addressStr}#EG${this.userId}
${this.$t("contactWrhUSA")}: ${addressJSON["name"]}, 
${this.$t("phoneWrhListUSA")}: ${addressJSON["phone"]}`;
              }

              warehouseItem["pr493"] = addressJSON;
            } else {
              warehouseItem["pr493_str"] = warehouseItem["pr493"];
            }

            warehousesList.push(warehouseItem);
          });

          this.warehousesList = warehousesList;
          this.isWarehousesLoaded = true;
        },
        () => {
          this.isWarehousesLoaded = true;
        } // server error callback
      );
    },

    copyWarehouse(addressItem: any) {
      // copy address text to copyDataClipboard
      logEventFA("present_offer", {
        item_category: getWarehouseName(addressItem["pr201"]),
        customer_id: this.userId,
      });
      // {idx: 0, item:CalcName: "Shenzhen warehouse",Object_id: 1113643,State_id: -5001,pr201: 287,pr201_iso: "CN",pr211: 180,pr_389: "502-503 }
      let objId = addressItem["Object_id"];
      let warehouseTextEl: any = document.getElementById("warehouse" + objId);
      warehouseTextEl.focus();
      // warehouseTextEl.select()
      warehouseTextEl.setSelectionRange(0, 9999);

      // let addressTextEl: any = this.$refs.addressTextEl
      // addressTextEl.value = addressItem.pr493_str || addressItem.CalcName
      try {
        // try copy
        document.execCommand("copy");
        // deselect selected text
        let w: any = window;
        if (w["getSelection"]) {
          w.getSelection().removeAllRanges();
        } else if (document["selection"]) {
          document["selection"].empty();
        }
        this.$root.$emit(eventsRegister.addMessage, {
          type: "success",
          message: this.$t("addrCopied"),
          autoClose: true,
        });
      } catch (err) {
        console.log("Oops, unable to copy");
      }
    },

    copyOneWarehouseDetail(elId: string, addressItem: any, name: string) {
      let warehouseDetailEl: any = document.getElementById(elId);
      warehouseDetailEl.focus();
      // warehouseDetailEl.select()
      warehouseDetailEl.setSelectionRange(0, 9999);
      // let addressTextEl: any = this.$refs.addressTextEl
      // addressTextEl.value = addressItem.pr493_str || addressItem.CalcName
      try {
        document.execCommand("copy");
        let w: any = window;
        // deselect selected text
        if (w["getSelection"]) {
          w.getSelection().removeAllRanges();
        } else if (document["selection"]) {
          document["selection"].empty();
        }
        this.$root.$emit(eventsRegister.addMessage, {
          type: "success",
          message: this.$t("addrCopied"),
          autoClose: true,
        });
      } catch (err) {
        console.log("Oops, unable to copy");
      }
      logEventFA("unlock_achievement", {
        item_category: getWarehouseName(addressItem["pr201"]),
        customer_id: this.userId,
        achievement_id: name,
      });
    },

    userAddressActions2(actionObj: any) {
      // {item: {...}, idx: 0}
      if (actionObj["idx"] === 0)
        // create new address from copy
        this.$root.$emit(eventsRegister.showPopup, {
          body: actionObj.item,
          header: this.$t("addressNew"),
          type: "addressNew",
        });
      else if (actionObj["idx"] === 1)
        // remove address action
        this.removeAddressConfirm(actionObj.item);
    },
    userAddressActions3(actionObj: any) {
      // {item: {...}, idx: 0}
      if (actionObj["idx"] === 0)
        // set default address
        this.setDefaultAddress(actionObj.item);
      else if (actionObj["idx"] === 1)
        // create new address from copy
        this.$root.$emit(eventsRegister.showPopup, {
          body: actionObj.item,
          header: this.$t("addressNew"),
          type: "addressNew",
        });
      else if (actionObj["idx"] === 2)
        // remove address action
        this.removeAddressConfirm(actionObj.item);
    },

    removeAddressConfirm(addressObj: any) {
      // {ID: 451, ...}
      this.$root.$emit(eventsRegister.showDialoq, {
        message: "Видалити цю адресу?",
        callback: (isTrue: boolean) => {
          if (isTrue) {
            const userID = this.userId;
            let delSavedAddressRequestData = {
              tmp_object_list: [
                { OBJECT_ID: userID, Class_id: 22600, ObjectDB_id: userID },
              ],
              tmp_object_property_det: [
                {
                  OBJECT_ID: userID,
                  Row_id: 1,
                  Property_Master_id: 230,
                  Property_id: -309,
                  VALUE: addressObj.ID,
                  Oper: "D",
                },
              ],
            };
            createRequest(
              "objEdit",
              delSavedAddressRequestData,
              (responseData: any) => {
                // Success callback function
                this.$root.$emit(eventsRegister.addMessage, {
                  type: "success",
                  message: this.$t("addressDeleted"),
                });
                // Update savedAddreses
                this.fetchSavedAddresses();
              },
              () => {}
            );
          }
        },
      });
    },

    setDefaultAddress(addressItem: any) {
      const addressId = addressItem.ID;
      const userID = this.userId;
      let delSavedAddressRequestData = {
        tmp_object_list: [
          { OBJECT_ID: userID, Class_id: 22110, ObjectDB_id: userID },
        ],
        tmp_object_property_det: [
          {
            OBJECT_ID: userID,
            Row_id: 1,
            Property_Master_id: 230,
            Property_id: -309,
            VALUE: addressId,
            Oper: "U",
          },
          {
            OBJECT_ID: userID,
            Row_id: 1,
            Property_Master_id: 230,
            Property_id: -298,
            VALUE: 1,
            Oper: "U",
          },
        ],
      };
      createRequest(
        "objEdit",
        delSavedAddressRequestData,
        () => {
          // Success callback function
          this.fetchSavedAddresses();
          this.$root.$emit(eventsRegister.addMessage, {
            type: "success",
            message: this.$t("defaultAddressChangedAddr"),
          });
        },
        () => {}
      );

      return false;
    },

    shareWarehouse(warehouseItem: any) {
      // Warn: do not format multiline string
      let warehouseText = "";
      // china warehouse 287, "HongKong warehouse" 286
      if (warehouseItem.pr201 === 286 || warehouseItem.pr201 === 287) {
        // Warn: do not format multiline string
        warehouseText = `你好！我在使用移动递送easyget,请发我的货到这个地址: 
${warehouseItem.pr493_str}
${this.$t("contactWrhCN")}: ${warehouseItem["pr493"]["name"]}, 
${this.$t("phoneWrhListCN")}: ${warehouseItem["pr493"]["phone"]}`;
      } else if (warehouseItem.pr201 === 284) {
        // usa warehouse
        // Warn: do not format multiline string
        warehouseText += `Hello! I’m using mobile delivery service EasyGet. Ship my order to address:
${warehouseItem.pr493_str}
${this.$t("contactWrhUSA")}: ${warehouseItem["pr493"]["name"]}, 
${this.$t("phoneWrhListUSA")}: ${warehouseItem["pr493"]["phone"]}`;
      }

      const sharePlugin = window["plugins"] && window["plugins"].socialsharing;
      let nav: any = navigator;

      if (!sharePlugin) {
        nav.share(
          warehouseText || warehouseItem.CalcName,
          warehouseItem.CalcName,
          "plain/text"
        );
      } else {
        (sharePlugin as any).shareWithOptions({
          message: warehouseText || warehouseItem.CalcName,
          chooserTitle: warehouseItem.CalcName,
        });
      }

      logEventFA("view_search_results", {
        item_category: getWarehouseName(warehouseItem["pr201"]),
        customer_id: this.userId,
      });
    },
  },
});
